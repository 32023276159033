<template>
  <div id="app">
    <Alert />
    <vue-extend-layouts />
    <!-- <Cookie v-if="CookieState" @close="CookieAccept" /> -->
    <ModalPopup v-if="ModalState" @close="ModalAccept" />
    <RegPopup v-if="RegPopupState" @close="RegPopupToggle" />
    <PopUpDefault
      @close="TgPopupState = false"
      :noClose="true"
      v-if="TgPopupState"
    >
      <div class="modal-popup__title">{{ user.first_name }}, добрый день!</div>
      <div class="body-popup__text text-normal">
        Присоединяйтесь в наш Telegram-канал, созданный для специалистов
        здравоохранения.
      </div>
      <div
        class="modal-popup__btn-accept d-md-inline-flex button button_pink"
        @click="goToTg()"
      >
        Перейти в Telegram
      </div>
    </PopUpDefault>
    <CoolLightBox
      class="colllightbox"
      :items="[
        {
          src: video,
          autoplay: false,
          muted: false,
          disablepictureinpicture: true,
        },
      ]"
      :index="index"
      @close="onClose()"
      @on-change="onOpen($event)"
      @on-open="onOpen($event)"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import VueExtendLayouts from "vue-extend-layout";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import Alert from "./components/pageComponents/Alert.vue";
import Cookie from "./components/pageComponents/Cookie.vue";
import ModalPopup from "./components/pageComponents/PopUp.vue";
import RegPopup from "./components/pageComponents/RegPopup.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "App",
  components: {
    VueExtendLayouts,
    Alert,
    Cookie,
    ModalPopup,
    RegPopup,
    PopUpDefault,
    CoolLightBox
  },
  data: () => ({
    CookieState: false,
    ModalState: false,
    RegPopupState: false,
    TgPopupState: false,
    video: '',
    videoCurrentTime: 0,
    index: null,
    videoElement: '',
    videoTime: 0
  }),
  computed: {
    ...mapGetters(["user", "Nosologys"]),
  },
  methods: {
    ...mapActions(["getSession", "fetchNosologys", "logout", "clearSearch"]),
    async onOpen(e) {
      const vm = this
      this.videoElement = document.querySelectorAll('.cool-lightbox-video')[e]
      if (this.videoElement) {
        this.videoElement.play()
        this.videoElement.disablePictureInPicture = true
        this.videoElement.disableRemotePlayback = true
      }
    },
    onClose() {
      this.index = null
      this.videoTime = 0
      this.video = ''
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture()
      }
    },
    playVideo(src) {
      this.video = src
      this.index = 0
    },
    CookieAccept(state) {
      this.CookieState = state;
      if (!state) {
        const date = new Date();
        date.setDate(date.getDate() + 1500);
        localStorage.setItem("policy", "true");
      }
    },
    ModalAccept(state) {
      this.ModalState = state;
      if (!state) {
        const date = new Date();
        date.setDate(date.getDate() + 1500);
        localStorage.setItem("modal", "true");
      }
    },
    RegPopupToggle(state) {
      this.RegPopupState = state;
    },
    tgPopupToggle(state) {
      this.TgPopupState = state;
      this.scrollLock(state);
    },
    goToTg() {
      if (this.user.invite_link) {
        window.open(this.user.invite_link, "_blank");
      }
      this.tgPopupToggle(false);
      this.$router.push({ name: "MainPage" }).catch(() => {});
    },
    getScrollBarSize() {
      let el = window.document.createElement("textarea"),
        style = {
          visibility: "hidden",
          position: "absolute",
          zIndex: "-2147483647",
          top: "-1000px",
          left: "-1000px",
          width: "1000px",
          height: "1000px",
          overflow: "scroll",
          margin: "0",
          border: "0",
          padding: "0",
        },
        support = el.clientWidth !== undefined && el.offsetWidth !== undefined;

      for (let key in style) {
        if (style.hasOwnProperty(key)) {
          el.style[key] = style[key];
        }
      }

      let size = null;
      if (support && window.document.body) {
        window.document.body.appendChild(el);
        size = [
          el.offsetWidth - el.clientWidth,
          el.offsetHeight - el.clientHeight,
        ];
        window.document.body.removeChild(el);
      }
      return size;
    },
    scrollLock(state) {
      if (state) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("html").style.overflowY = "hidden";
        document.querySelector("body").style.marginRight = "";
      } else {
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
        document.querySelector("body").style.marginRight =
          "-" + this.getScrollBarSize()[0] + "px";
      }
    },
    setTimecode(t) {
      let times = t.split(",");
      let videos = this.$el.querySelectorAll("video");
      for (let ind in times) {
        if (videos[ind] && !!parseInt(times[ind])) {
          videos[ind].currentTime = times[ind];
        }
      }
    },
  },
  async mounted() {
    // if (!Object.keys(this.user).length) {
    //   await this.getSession();
    // }
    const vm = this
    document.addEventListener('click', function(e){
       for (
        let target = e.target;
        target && target != this;
        target = target.parentNode
      ) {
        if (target.matches("*[data-coolbox]")) {
          if(!target.getAttribute('data-coolbox')) return
          vm.playVideo(target.getAttribute('data-coolbox'))
        }}
    });
    document.querySelector("body").style.marginRight =
      "-" + this.getScrollBarSize()[0] + "px";
    bus.$on("openPolicy", this.RegPopupToggle);
    // bus.$on("tgPopup", this.tgPopupToggle);
    bus.$on("scrollLock", this.scrollLock);

  
    
  },
  destroyed() {
    bus.$off("openPolicy", this.RegPopupToggle);
    // bus.$off("tgPopup", this.tgPopupToggle);
  },
  watch: {
    $route(to, from) {
      bus.data.result = [];
      if (
        !localStorage.getItem("modal") &&
        this.$route.name &&
        this.$route.name === "MainPage" ||
        (!localStorage.getItem("modal") && (this.$route.name === "GffPdf" ||
        this.$route.name === "laldPdf" ||
        this.$route.name === "NF-1") && this.user && Object.keys(this.user).length )
      ) {
        this.ModalAccept(true);
      }
      if (
        !localStorage.getItem("policy") &&
        this.$route.name !== "CancelSubscribtion"
      ) {
        this.CookieAccept(true);
      }
      setTimeout(() => {
        if (this.$route && this.$route.query && this.$route.query.t) {
          this.setTimecode(this.$route.query.t);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.modal-popup {
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    @media screen and (max-width: 767px) {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .body-popup {
    &__text {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      &.text-bold {
        font-weight: 500;
      }
      &.text-normal {
        font-weight: 400;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>