import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);
let routes = [];
if (process.env.VUE_APP_CLOSED && !document.cookie.includes("access")) {
  routes = [
    {
      path: "*",
      name: "Plug",
      component: load("Plug"),
      meta: {
        needAuth: false,
        layout: "empty",
      },
    },
  ];
} else {
  routes = [
    {
      path: "/",
      name: "MainPage",
      component: load("MainPage"),
      meta: {
        layout: "main",
      },
    },
    {
      path: "/therapeutic-areas/:slug",
      name: "NosologyDetail",
      component: load("NosologyDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/therapeutic-areas/:slug/:disease",
      name: "Disease",
      component: load("SubgroupLayout"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    // {
    //   path: "/therapeutic-areas/:slug/:subgroup",
    //   name: "Subgroup",
    //   component: load("SubgroupLayout"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //   },
    // },
    {
      path: "/therapeutic-areas/:slug/:subgroup/:disease",
      name: "SupDisease",
      component: load("Disease"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: load("auth/Register"),
    //   meta: {
    //     layout: "auth",
    //   },
    // },
    // {
    //   path: "/tg-register",
    //   name: "TgRegister",
    //   component: load("auth/Register"),
    //   meta: {
    //     layout: "auth",
    //   },
    // },
    // {
    //   path: "/login",
    //   name: "Login",
    //   component: load("auth/Login"),
    //   meta: {
    //     layout: "auth",
    //   },
    // },
    // {
    //   path: '/user-update',
    //   name: 'UserUpdate',
    //   component: load('auth/UserUpdate'),
    //   meta: {
    //     layout: 'auth'
    //   }
    // },
    {
      path: "/library",
      name: "Library",
      component: load("library/Library"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/videomaterials/",
      name: "VideoMaterials",
      component: load("library/VideoMaterials"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/podcasts/",
      name: "Podcasts",
      component: load("library/Podcasts"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/textmaterials",
      name: "TextMaterials",
      component: load("library/TextMaterials"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/textmaterials/:slug",
      name: "TextDetail",
      component: load("library/TextDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/videomaterials/:slug",
      name: "VideoDetail",
      component: load("library/VideoDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/podcasts/:slug",
      name: "PodcastDetail",
      component: load("library/PodcastDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/cabinet",
      name: "Cabinet",
      component: load("Cabinet"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials",
      name: "Materials",
      component: load("cabinet/Materials"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/recommended-events",
      name: "RecommendedEvents",
      component: load("cabinet/Events"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/recommended-events/:type(active|passed)",
      name: "RecommendedEventsDetail",
      component: load("cabinet/EventsDetail"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials/settings",
      name: "MaterialsSettings",
      component: load("cabinet/MaterialsSettings"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/recommended-events/settings",
      name: "RecommendedEventsSettings",
      component: load("cabinet/MaterialsSettings"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials/:type(text|podcasts|video|diseases|medications|projects)",
      name: "MaterialsDetail",
      component: load("cabinet/MaterialsDetail"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/favorites",
      name: "Favorites",
      component: load("cabinet/Favorites"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/history",
      name: "History",
      component: load("cabinet/History"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/watch-later",
      name: "WatchLater",
      component: load("cabinet/WatchLater"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/restore-password",
      name: "RestorePassword",
      component: load("auth/RestorePassword"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/therapeutic-areas",
      name: "Nosology",
      component: load("Nosology"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medications",
      name: "Medications",
      component: load("Medications"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "BetalockLand",
      path: "/medication/betalok",
      component: load("anotherPages/Betalock"),
      meta: {
        layout: "betalock",
        auth: true,
      },
    },
    {
      path: "/medication/:medication",
      name: "DrugsTemplates",
      component: load("DrugsTemplates"),
      meta: {
        layout: "main",
        noStickyHead: true,
        auth: true,
      },
    },
    {
      path: "/events",
      name: "Events",
      component: load("events/Events"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/event/:id",
      name: "EventDetail",
      component: load("events/EventDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/contacts",
      name: "ContactUs",
      component: load("ContactUs"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/report-adverse-event",
      name: "ReportAdverseEvent",
      component: load("ReportAdverseEvent"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/complaint-to-the-drug",
      name: "ComplaintDrug",
      component: load("ComplaintDrug"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/report-a-drug-claim",
      name: "ReportDrugClaim",
      component: load("ReportDrugClaim"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medical-info",
      name: "MedicalInfo",
      component: load("MedicalInfo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/betalok",
      name: "Betalok",
      component: load("Betalok"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/all-rights-reserved",
      name: "AllRightsReserved",
      component: load("AllRightsReserved"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/oauth",
      name: "OAuth",
      component: load("auth/OAuth"),
      meta: {
        layout: "empty",
      },
    },
    {
      path: "/az-sso",
      name: "AzSsoAuth",
      component: load("auth/AzSsoAuth"),
      meta: {
        layout: "empty",
      },
    },
    {
      path: "/map",
      name: "Map",
      component: load("Map"),
      meta: {
        layout: "empty",
        auth: true,
      },
    },
    // {
    //   path: "/email-safnelo",
    //   name: "EmailSafnelo",
    //   component: load("EmailSafnelo"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      path: "/skv-academy",
      name: "SkvAcademy",
      component: load("anotherPages/SkvAcademy"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/faq-lald",
      name: "Lald",
      component: load("anotherPages/Lald"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/faq-hpp",
      name: "Gff",
      component: load("anotherPages/Gff"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/lald",
      name: "LaldClosed",
      component: load("anotherPages/Lald"),
      meta: {
        layout: "main",
        auth: false,
        closed: true,
      },
    },
    {
      path: "/hpp",
      name: "GffClosed",
      component: load("anotherPages/Gff"),
      meta: {
        layout: "main",
        auth: false,
        closed: true,
      },
    },
    {
      path: "/hpp-pdf",
      name: "GffPdf",
      component: load("anotherPages/Lald-pdf"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/lald-pdf",
      name: "laldPdf",
      component: load("anotherPages/Gff-pdf"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/bremya_skv",
      name: "Skv",
      component: load("anotherPages/Skv"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/galaxy",
      name: "Galaxy",
      component: load("anotherPages/Galaxy"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/galaxy/:frame",
      name: "GalaxyFrame",
      component: load("anotherPages/GalaxyFrame"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/med-info",
      name: "MedInfo",
      component: load("MedInfo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/vremya-hobl",
      name: "TimeHobl",
      component: load("anotherPages/TimeHobl"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/vremya-hobl/:frame",
      name: "TimeHoblFrame",
      component: load("anotherPages/TimeHoblFrame"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    // {
    //   path: '/narodnye-antiagregantnye-skazki',
    //   name: 'NarodnyeSkazki',
    //   component: load('anotherPages/NarodnyeSkazki'),
    //   meta: {
    //     layout: 'main',
    //     auth: true
    //   }
    // },
    {
      path: "/stream-test",
      name: "Stream",
      component: load("anotherPages/Stream"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/safnelo-p1",
      name: "safneloPartOne",
      component: load("anotherPages/Safnelo-1"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/safnelo-p2",
      name: "safneloPartTwo",
      component: load("anotherPages/Safnelo-2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/tg",
      name: "ToTgChanel",
      component: load("ToTgChanel"),
      meta: {
        layout: "empty",
        auth: true,
      },
    },
    {
      path: "/forsiga",
      name: "Forsiga",
      component: load("anotherPages/Forsiga"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/krepost",
      name: "Krepost",
      component: load("anotherPages/Krepost"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/komplement-forum",
      name: "Ultomiris",
      component: load("anotherPages/Ultomiris"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/bolshe",
      name: "SeeMore",
      component: load("anotherPages/SeeMore"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/epitelium",
      name: "Epitelium",
      component: load("anotherPages/Epitelium"),
      meta: {
        layout: "main_black",
        auth: false,
      },
    },
    {
      path: "/epitelium/game",
      name: "EpiteliumGame",
      component: load("anotherPages/EpiteliumGame"),
      meta: {
        layout: "main_black",
        auth: true,
      },
    },
    {
      path: "/epitelium/quiz",
      name: "EpiteliumQuiz",
      component: load("anotherPages/EpiteliumQuiz"),
      meta: {
        layout: "main_black",
        auth: true,
      },
    },
    {
      path: "/skv_index_calculator",
      name: "SkvQuestions",
      component: load("anotherPages/SkvQuestions"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/enhertu",
      name: "EnhertuESMO",
      component: load("anotherPages/EnhertuESMO"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/event-rmg",
      name: "EventRMG",
      component: load("anotherPages/EventRMG"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/event-oc",
      name: "EventOC",
      component: load("anotherPages/EventOC"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/epicentral",
      name: "Epicentral",
      component: load("epicentral/Epicentral"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/epicentral/:slug",
      name: "EpicentralDetail",
      component: load("epicentral/EpicentralDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "EventHSN",
      path: "/event-hsn",
      component: load("anotherPages/EventHSN"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/nf-1",
      name: "NF-1",
      component: load("anotherPages/NF1"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/new-year-video",
      name: "NewYearVideo",
      component: load("anotherPages/NewYearVideo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "Projects",
      path: "/projects",
      component: load("projects/Projects"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "ArchiveEvents",
      path: "/archive-events",
      component: load("archiveEvents/ArchiveEvents"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "HER2",
      path: "/medication/enhertu/her2",
      component: load("medications/enhertu/her2/HER2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "HER2LOW",
      path: "/medication/enhertu/her2low",
      component: load("medications/enhertu/her2low/HER2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "Education",
      path: "/trainings",
      alias: ['/education'],
      component: load("education/Education"),
      meta: {
        layout: "education",
        auth: true,
      },
    },
    {
      name: "EducationDetail",
      path: "/trainings/:slug",
      alias: ['/education/:slug'],
      component: load("education/EducationDetail"),
      meta: {
        layout: "education",
        auth: true,
      },
    },
    {
      name: "Surveys",
      path: "/surveys",
      component: load("Surveys"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "SevereAsthma",
      path: "/severeasthmanewera",
      component: load("anotherPages/SevereAsthma"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeSPB",
      path: "/vremyaspb",
      component: load("anotherPages/TimeSPB"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeNSK",
      path: "/vremyansk",
      component: load("anotherPages/TimeNSK"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeUFA",
      path: "/vremyaufa",
      component: load("anotherPages/TimeUFA"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/prohbp",
      name: "ProHBP",
      component: load("anotherPages/ProHBP"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/10years",
      name: "10Years",
      component: load("anotherPages/10years"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    // {
    //   path: "/regiony-vpered",
    //   name: "RegionsForward",
    //   component: load("anotherPages/RegionsForward"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //   },
    // },
    {
      path: "/success",
      name: "Unsubscribe",
      component: load("Unsubscribe"),
      meta: {
        layout: "empty",
        auth: false,
      },
    },
    {
      path: "/pod_prikrutiem",
      name: "PodPrikrutiem",
      component: load("anotherPages/PodPrikrutiem"),
      meta: {
        layout: "main_black",
        auth: true,
      },
    },
    // {
    //   path: "/ne-po-sekrety",
    //   name: "NePoSekrety",
    //   component: load("anotherPages/NePoSekrety"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      path: "/tezspire",
      name: "Tezspire",
      component: load("anotherPages/Tezspire"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeIrkutsk",
      path: "/vremyairkutsk",
      component: load("anotherPages/TimeIrkutsk"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "SovremennayaNevrologiya",
      path: "/sovremennaya-nevrologiya",
      component: load("anotherPages/SovremennayaNevrologiya"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "BannersPage",
      path: "/neurology",
      component: load("anotherPages/BannersPage"),
      meta: {
        layout: "special",
        auth: false,
      },
    },
    {
      name: "NevroPromo",
      path: "/attr",
      component: load("anotherPages/NevroPromo"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",
          }
        ]
      },
    },
    {
      name: "SD2",
      path: "/sd2",
      component: load("anotherPages/SD2"),
      meta: {
        layout: "sd2",
        auth: false,
      },
    },
    {
      path: "*",
      name: "404",
      component: load("404"),
      meta: {
        layout: "main",
      },
    },
  ];
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        to.hash.replace("#", "") &&
        document.getElementById(to.hash.replace("#", ""))
      ) {
        return {
          selector: to.hash,
        };
      } else {
        if (to.path !== from.path) {
          return { x: 0, y: 0 };
        }
      }
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const _to = to.path.replace(/\/$/, "");
  //const _from = from.fullPath.replace(/\/$/, "");
  //const _current = window.location.pathname.replace(/\/$/, "");
  const flag = _to !== "" && to.path && to.path.match(/\/$/);
  if (flag) {
    let _to2 = "";
    for (let i = 1; i < to.path.split(/\//).length - 1; i++) {
      _to2 += "/" + to.path.split(/\//)[i];
    }
    next({ ...to, path: _to2 });
  };
  if (to.name === "Cabinet" && to.query && to.query.access_token) {
    localStorage.setItem("token", to.query.access_token);
    axios.defaults.headers.common.Authorization =
      "Bearer " + to.query.access_token;
    if (await store.dispatch("getSession")) {
      let query = { ...to.query };
      delete query.access_token;
      return next({ ...to, query: { ...query } });
    } else {
      localStorage.removeItem("token");
      axios.defaults.headers.common.Authorization = null;
      if (to.query.next_url) {
        let url;
        try {
          url = new URL(to.query.next_url || to.query.source);
        } catch {
          url = new URL("https://" + (to.query.next_url || to.query.source));
        }
        window.open(url, "_self");
      } else {
        next({
          name: "MainPage",
        });
      }
    }
  }
  if (!from || !from.name) {
    await store.dispatch("getSession");
  }
  const isAuth = localStorage.getItem("token");

  if (isAuth && isAuth !== "undefined") {
    if (
      to.name === "Login" ||
      to.name === "Register" ||
      to.name === "TgRegister"
    ) {
      if (to.name === "TgRegister") {
        next({
          name: "ToTgChanel",
          params: { tg: true },
        });
      } else {
        next({
          name: "MainPage",
        });
      }
    }
  }

  if (to.matched.some((record) => record.meta.auth)) {
    const ssoLinks = {
      registration: `${process.env.VUE_APP_SSO_HOST}signup?source=${window.location.href}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.origin + to.fullPath)}`,
      login: `${process.env.VUE_APP_SSO_HOST}signin?source=${window.location.href}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.origin + to.fullPath)}`
    }
    if (Object.keys(to.query) && to.query.access) {
      switch (to.name) {
        case "NosologyDetail":
          if (!await store.dispatch("fetchNosologyDetail", {
            slug: to.params.slug,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "Disease":
          if (!await store.dispatch("fetchDisease", {
            disease: to.params.disease,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "SupDisease":
          if (!await store.dispatch("fetchDisease", {
            disease: to.params.disease,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "EventDetail":
          if (!await store.dispatch("fetchEventDetail", {
            id: to.params.id,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "DrugsTemplates":
          if (!await store.dispatch("fetchMedicationDetail", {
            slug: to.params.medication,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "TextDetail":
          if (!await store.dispatch("fetchTextDetail", {
            slug: to.params.slug,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "VideoDetail":
          if (!await store.dispatch("fetchVideoDetail", {
            slug: to.params.slug,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "PodcastDetail":
          if (!await store.dispatch("fetchPodcastDetail", {
            slug: to.params.slug,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "EpicentralDetail":
          if (!await store.dispatch("fetchEpicentralDetail", {
            slug: to.params.slug,
            access: to.query.access,
          })) {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
          break;
        case "Map":
          if (to.query.access === "88ed053ef4280c1153ad0add4ee4ae2f") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }

          }
          break;
        case "Events":
          if (to.query.access === "ZXZlbnRzX3BhZ2VfYXpfbW9zdF9kZWVwbGluaw") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "Betalok":
          if (to.query.access === "72194fe7d6eb1600b9518f0ceab1fde8") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "Galaxy":
          if (to.query.access === "e03239b27e34a5f7f3bde739459dd537") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "SkvAcademy":
          if (to.query.access === "ce10238dbf11023c43ddf27a9e3b741e") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "TimeHobl":
          if (to.query.access === "3a7dbae2ea7e84704761e4aba89351a6") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "NarodnyeSkazki":
          if (to.query.access === "72d9de5d2c64b936e4f0eddea673b238") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "safneloPartOne":
          if (to.query.access === "8a8fb39220c0feb3d073ef048d7865e1") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "safneloPartTwo":
          if (to.query.access === "9220c0feb8a8fb33d073ef048d7865e1") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "Forsiga":
          if (to.query.access === "0033db14bd55bdb6430356c78161a48a") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "Krepost":
          if (to.query.access === "6adcc8c54f28a46f5c85c2cd4d14fe8a") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "SeeMore":
          if (to.query.access === "5fac779c6088c56208e4ed39226da2a4") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "ProHBP":
          if (to.query.access === "6c3682006734b0e048ede84f5a1051eb") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "Ultomiris":
          if (to.query.access === "43b3bd25d07ee8bdd8cb095b2cc2c8ed") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EnhertuESMO":
          if (to.query.access === "1f61f3f9a5885738a40d6358a7367550") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EventOC":
          if (to.query.access === "0a0129952b1615c67da3e3f9a7b24fb1") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EventRMG":
          if (to.query.access === "b2af30fe22a59ac1e6dfb16cb459ce25") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EventOC":
          if (to.query.access === "0a0129952b1615c67da3e3f9a7b24fb1") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EventHSN":
          if (to.query.access === "a5eb415107f777c5caf081a6b9c0dcf8") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "Epitelium":
          if (to.query.access === "589fab7cd12b743cbc766e6a7d950fd4") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EpiteliumGame":
          if (to.query.access === "589fab7cd12b743cbc766e6a7d950fd4") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EventRMG":
          if (to.query.access === "b2af30fe22a59ac1e6dfb16cb459ce25") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "EpiteliumQuiz":
          if (to.query.access === "589fab7cd12b743cbc766e6a7d950fd4") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "NewYearVideo":
          if (to.query.access === "5bbc6b13f0417e8b117f35f06e096859") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "SkvQuestions":
          if (to.query.access === "125a075567e2d2b16bad6258a1b9b750") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "HER2":
          if (to.query.access === "76695599902b840f8b091739827a36b8") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
          case "HER2LOW":
            if (to.query.access === "17c31c15d516c6feedcd74ab305c2522") {
              next();
            } else {
              if (isAuth) {
                next();
              } else {
                next(false);
                window.open(ssoLinks.login, "_self");
              }
            }
            break;
        case "EducationDetail":
          if (to.query.access === "4fe032cd8d505abf3748ae8000f5aa87") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "SevereAsthma":
          if (to.query.access === "ca6d38567f639ada8c398babb1fdc176") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "TimeSPB":
          if (to.query.access === "cf75ec93ee22de56e9e21fda48188d3e") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "TimeNSK":
          if (to.query.access === "7c464645572ad9a7aca64d854b280d8a") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "10years":
          if (to.query.access === "74dca3f5d0cca84079f051f98fc91e2f") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "RegionsForward":
          if (to.query.access === "b7d33756cb90684ba0a6f21e8630969a") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "SovremennayaNevrologiya":
          if (to.query.access === "8318e29125c31a725a9c454f7cb66c48") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
        case "PodPrikrutiem":
          if (to.query.access === "814d0939f484fb970547a4af8b79ece9") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
          case "BetalockLand":
          if (to.query.access === "13a1dea8138f04438571e2f7c3d65487") {
            next();
          } else {
            if (isAuth) {
              next();
            } else {
              next(false);
              window.open(ssoLinks.login, "_self");
            }
          }
          break;
          
        default:
          if (isAuth) {
            next();
          } else {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
      }
    } else if (!isAuth) {
      window.open(ssoLinks.login, '_self')
      return next(false)
    }
  }

  next();
});

export default router;
