import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex, { mapGetters } from 'vuex'
import axios from './plugins/axios.js'
import VueMeta from 'vue-meta'

import vSelect from "vue-select";
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually


import moment from 'moment'
import 'moment/locale/ru';

import helpers from './helpers'

import './assets/scss/styles.scss'
Vue.component("v-select", vSelect);
Vue.use(VueCroppie);
Vue.use(axios);
Vue.config.productionTip = false


Vue.prototype.helpers = helpers
moment.locale('ru')
Vue.prototype.$m = moment

Vuex.Store.prototype.helpers = helpers

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

export const bus = new Vue();
bus.data = { result: [] }
new Vue({
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  computed: {
    ...mapGetters(['user']),
    host() {
      return process.env.VUE_APP_BACKEND_HOST;
    },
    encodedEmail() {
      if (this.user && Object.keys(this.user).length) {
        return this.helpers.b64EncodeUnicode(this.user.email)
      }
      return ""
    },
    encodedIp() {
      if (this.user && Object.keys(this.user).length) {
        return this.helpers.b64EncodeUnicode(this.user.ip_address)
      }
      return ""
    },
    mdmId() {
      if (this.user && Object.keys(this.user).length) {
        return this.user.mdm_id
      }
      return ""
    },
    userDiseases() {
      if (this.user && Object.keys(this.user).length) {
        return this.user.diseases.map((el) => el.id)
      }
      return []
    },
    ymFields() {
      return {
        url: this.$route.fullPath,
        'UserID': this.encodedEmail,
        'IPAdress': this.encodedIp,
        'Areas': this.userDiseases,
        'MDMID': this.mdmId
      }
    },
    isMobile: function () {
      return this.windowWidth < 768;
    },
    isTablet: function () {
      return this.windowWidth < 1220 && this.windowWidth > 767;
    },
    ssoLinks() {
      return {
        registration: `${process.env.VUE_APP_SSO_HOST}signup?source=${window.location.href}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.href)}`,
        login: `${process.env.VUE_APP_SSO_HOST}signin?source=${window.location.href}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.href)}`
      }
    }

  },
  async mounted() {

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
   


  },
  watch: {
    '$route': {
      handler: async function (n, o) {
        if (o?.name && o.path !== n.path) {
          if (typeof ym !== 'undefined') {
            ym(91468266, 'hit', n.fullPath)
          }
        }
        setTimeout(() => {
          document.querySelectorAll('video').forEach((v) => {
            v.setAttribute('controlsList', "nodownload")
            v.addEventListener("contextmenu", (event) => { event.preventDefault() });
          })
        }, 1000)
      },
      deep: true,
      immediate: true,
    },
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
